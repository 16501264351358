.service {
    border-radius: 0.5rem;
    padding: 26px 20px;
    position: relative;
}
.service_seleted {
    border-radius: 0.5rem;
    padding: 48px 20px 26px 20px;
    position: relative;
}
.services_modalBody {
    width: 840px;
}
.services_service {
    border-radius: 0.5rem;
    padding: 4px;
    &.services_selectedService {
        border-width: 2px;
        border-style: dashed;
    }
}
.service_sevicesList {
    overflow: auto;
}
.selectedService {
    position: relative;

    &:before {
        content: '';
        top: -2px;
        left: -2px;
        width: calc(100% + 2px);
        height: calc(100% + 2px);
        border: 2px solid #263446;
    }
}
.services_modelFooter {
    margin-top: 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
