.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 24px;
    overflow: auto;
    overscroll-behavior-y: contain;
    z-index: 102;
}
.modal--body {
    display: flex;
    flex-direction: column;
    background: #fff;
    border-radius: 8px;
    -webkit-box-shadow: 0 15px 25px -6px rgb(0 0 0 / 10%);
    box-shadow: 0 15px 25px -6px rgb(0 0 0 / 10%);
    padding: 24px;
    max-width: 82vw;
}
.modal--close {
    display: flex;
    justify-content: end;
    margin-bottom: 0.75rem;
}
.modal--close .modal--icon {
    width: 34px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #3f4254;
    background-color: #e4e6ef;
    border-radius: 8px;
    font-size: 1.2rem;
}
.modal--content {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    display: flex;
    flex-direction: column;
}
.modal--footer {
    padding: 24px;
}
