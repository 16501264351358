.actionBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    position: relative;
    &::before {
        position: absolute;
        height: 20px;
        width: 20px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transform-origin: 50% 50%;
        content: '';
        background-color: #e5e9f2;
        border-radius: 50%;
        opacity: 0;
        transition: all 0.3s;
    }
    &:hover::before {
        width: 44px;
        height: 44px;
        opacity: 1;
    }
    &:hover i {
    }

    i {
        z-index: 1;
        width: 20px;
        height: 20px;
        line-height: 20px;
        font-size: 1.3rem;
    }
}

.userAvatar {
    width: 36px;
    height: 36px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
        font-size: 0.85rem;
    }
}

.notifications_menu {
    padding: 0;
    width: 400px;
    &:before {
        content: none;
    }
    @media screen and (min-width: 560px) and (max-width: 700px) {
        width: 280px;
    }
    @media screen and (min-width: 400px) and (max-width: 560px) {
        right: -10rem;
        width: 280px;
    }
    @media screen and (min-width: 260px) and (max-width: 399px) {
        right: -8rem;
        width: 260px;
    }
}
.notifications_header {
    background-color: #1a202c;
    background-position: center center;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    background-size: cover;
    padding: 24px;
}
.notifications_body {
    height: 260px;
    overflow-y: scroll;
}
.notification_icon {
    width: 32px;
    height: 32px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    i {
        font-size: 1.125rem;
    }
}
.notification_details {
    font-size: 13px;
    padding: 0.1rem;
}
.notification_title {
    font-weight: 500;
}

.setting_menu {
    padding: 0;
    width: 200px;
    &:before {
        content: none;
    }
}
.calender_menu {
    padding: 0;
    width: 235px;
    &:before {
        content: none;
    }
    @media screen and (min-width: 500px) and (max-width: 768px) {
        right: -7rem;
    }
    @media screen and (min-width: 400px) and (max-width: 499px) {
        right: -10rem;
    }

    @media screen and (min-width: 260px) and (max-width: 399px) {
        right: -12rem;
    }
}
