@import '../fonts/fonts.css';

@import '../fonts/font-awesome/css/all.css';

@import './ui/Symbol.css';
@import './ui/Card.css';
@import './ui/ApexCharts.css';
@import './ui/Table.css';
@import './ui/ServiceColors.css';
@import './ui/Tabs.css';
@import './ui/Modal.css';
@import './ui/Label.css';

html,
body {
    font-size: 14px;
    padding: 0;
    margin: 0;
    font-family: 'Euclid Circular B';
    height: 100%;
    width: 100%;
}

body {
    background-color: #edf2f7;
    font-size: 14.5px;
    color: #263446;
    width: 100%;
    height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -ms-scroll-chaining: none;
    overscroll-behavior: none;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

#root {
    width: 100%;
    height: 100%;
    overflow: auto;
}

.layout {
    width: 100%;
    height: 100%;
    overflow: auto;
}

a {
    color: inherit;
    text-decoration: none;
}

* {
    box-sizing: border-box;
}

.layout-no--padding {
    margin: -2rem;
}

.layout-wrapper {
    margin-left: 260px;
}

.btn {
    padding: 13px 26px;
    font-weight: 600;
    text-transform: capitalize;
    color: #fff;
    background: #27292e;
    border-radius: 8px;
    font-size: 13px;
}
.btn.btn-md {
    padding: 10px 22px;
}
.btn.btn-icon {
    padding: 0;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.btn.btn-icon i {
    font-size: 1.2rem;
}
.btn.disabled {
    background-color: #d6d6d7;
    color: #fff;
    border: 1 px solid #ebebeb;
    cursor: not-allowed;
}
.btn.btn-secondary {
    color: #3f4254;
    border-color: #e4e6ef;
    background-color: #e4e6ef;
}
.btn.btn-border {
    @apply border text-black-500;

    background-color: #fff;
}
.active {
    color: #fff;
}
.activeTab {
    background: #2e3648;
    color: #fff;
}
.activeIcon {
    color: #fff;
}
.activeIconColor {
    color: #fff;
}
.isActivelist {
    background-color: #fff;
}
.isActiveDay {
    background-color: rgb(57, 57, 185);
    color: white;
}
