.checkout_layoutWrapper {
    width: 100%;
    max-width: 980px;
    padding-right: 316px;
    position: relative;
    height: 100%;
}
.checkout_layoutTitle {
    font-size: 25px;
    font-weight: 600;
}
.checkout_content {
    padding: 24px;
}

.checkout_basketSidebar {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 316px;
    background-color: #fff;
    z-index: 99;
    box-shadow: none;
    display: flex;
    flex-direction: column;

    .basket_header {
        padding: 20px;
    }
    .basket_body {
        flex: 1;
        padding: 20px;
        display: flex;
        flex-direction: column;
    }
    .basket_empty {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        flex: 1;

        img {
            max-width: 90px;
        }
    }

    .basket_footer {
        padding: 0 20px 20px;
    }
}
.checkout_selectCustomer {
    display: flex;
    align-items: center;
    box-shadow: none;
    background: #fff;
    padding: 1rem 16px;
    border-radius: 0.5rem;
    width: 100%;
    border: 1px solid rgba(223, 226, 230);
    cursor: pointer;
}

.services_service {
    border-radius: 0.5rem;
    padding: 4px;
    &.services_selectedService {
        border-width: 2px;
        border-style: dashed;
    }
}
.service_sevicesList {
    overflow: auto;
}
.selectedService {
    position: relative;

    &:before {
        content: '';
        top: -2px;
        left: -2px;
        width: calc(100% + 2px);
        height: calc(100% + 2px);
        border: 2px solid #263446;
    }
}

ul.basket_itemsList {
    li:not(:last-child) {
        @apply border-b border-dashed;
    }
    .basket_singleItem {
        padding: 10.5px 0;

        .item_name {
            font-size: 13px;
            display: flex;
            align-items: center;
        }
        .item_amount {
            font-size: 13.8px;
            font-weight: 500;
            display: flex;
            align-items: center;
            justify-content: end;
        }
        .item_quantity {
            @apply text-gray-700;
            font-size: 12px;
            display: flex;
            align-items: center;
        }
    }
}

.gratuity_tip {
    @apply border;

    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px 0 rgb(49 47 51 / 3%);
    padding: 12px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &.gratuity_tipActive {
        border: 2px solid #263446;

        div {
            &:first-child {
                font-weight: 500;
            }
        }
    }

    div {
        &:first-child {
            font-size: 14px;
        }
        &:last-child {
            font-size: 11.5px;
        }
    }
}

.payment_method {
    @apply border;

    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px 0 rgb(49 47 51 / 3%);
    padding: 12px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &.payment_methodActive {
        border: 2px solid #263446;
    }

    i {
        font-size: 24px;
    }

    div {
        margin-top: 4px;
        font-size: 13px;
    }
}

.checkout_successWrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 300px;
    margin: 0 auto;

    img {
        max-width: 220px;
    }
    .checkout_successTitle {
        font-weight: 800;
        font-size: 27px;
    }
}
