@font-face {
  font-family: 'Euclid Circular B';
  font-style: normal;
  font-weight: 300;
  src: local('Euclid Circular B'), url('EuclidCircularB/Euclid Circular B Light.woff') format('woff');
}

@font-face {
  font-family: 'Euclid Circular B';
  font-style: normal;
  font-weight: 400;
  src: local('Euclid Circular B'), url('EuclidCircularB/Euclid Circular B Regular.woff') format('woff');
}

@font-face {
  font-family: 'Euclid Circular B';
  font-style: italic;
  font-weight: 300;
  src: local('Euclid Circular B'), url('EuclidCircularB/Euclid Circular B Light Italic.woff') format('woff');
}

@font-face {
  font-family: 'Euclid Circular B';
  font-style: italic;
  font-weight: 400;
  src: local('Euclid Circular B'), url('EuclidCircularB/Euclid Circular B Italic.woff') format('woff');
}
  
@font-face {
  font-family: 'Euclid Circular B';
  font-style: normal;
  font-weight: 500;
  src: local('Euclid Circular B'), url('EuclidCircularB/Euclid Circular B Medium.woff') format('woff');
}
  
@font-face {
  font-family: 'Euclid Circular B';
  font-style: italic;
  font-weight: 500;
  src: local('Euclid Circular B Medium'), url('EuclidCircularB/Euclid Circular B Medium Italic.woff') format('woff');
}
  
@font-face {
  font-family: 'Euclid Circular B';
  font-style: normal;
  font-weight: 600;
  src: local('Euclid Circular B'), url('EuclidCircularB/Euclid Circular B SemiBold.woff') format('woff');
}
  
@font-face {
  font-family: 'Euclid Circular B';
  font-style: italic;
  font-weight: 600;
  src: local('Euclid Circular B'), url('EuclidCircularB/Euclid Circular B SemiBold Italic.woff') format('woff');
}

@font-face {
  font-family: 'Euclid Circular B';
  font-style: normal;
  font-weight: 700;
  src: local('Euclid Circular B'), url('EuclidCircularB/Euclid Circular B Bold.woff') format('woff');
}
  
@font-face {
  font-family: 'Euclid Circular B';
  font-style: italic;
  font-weight: 700;
  src: local('Euclid Circular B Bold Italic'), url('EuclidCircularB/Euclid Circular B Bold Italic.woff') format('woff');
}