.service-color-0--bd {
    border-color: #00beb7;
}
.service-color-0--bd-light {
    background-color: rgba(0, 190, 183, 0.5);
}
.service-color-0--bg {
    background-color: #d9f6f5;
}
.service-color-0--bg-2 {
    background-color: #00beb7;
}
.service-color-0--bg-light {
    background-color: rgba(217, 246, 245, 0.5);
}
.service-color-1--bd {
    border-color: #ff5e00;
}
.service-color-1--bd-light {
    background-color: rgba(255, 94, 0, 0.5);
}
.service-color-1--bg {
    background-color: #ffe7d9;
}
.service-color-1--bg-2 {
    background-color: #ff5e00;
}
.service-color-1--bg-light {
    background-color: rgba(255, 231, 217, 0.5);
}
.service-color-2--bd {
    border-color: red;
}
.service-color-2--bd-light {
    background-color: rgba(255, 0, 0, 0.5);
}
.service-color-2--bg {
    background-color: #ffd9d9;
}
.service-color-2--bg-2 {
    background-color: red;
}
.service-color-2--bg-light {
    background-color: rgba(255, 217, 217, 0.5);
}
.service-color-3--bd {
    border-color: #c400ff;
}
.service-color-3--bd-light {
    background-color: rgba(196, 0, 255, 0.5);
}
.service-color-3--bg {
    background-color: #f6d9ff;
}
.service-color-3--bg-2 {
    background-color: #c400ff;
}
.service-color-3--bg-light {
    background-color: rgba(246, 217, 255, 0.5);
}
.service-color-4--bd {
    border-color: #3df200;
}
.service-color-4--bd-light {
    background-color: rgba(61, 242, 0, 0.5);
}
.service-color-4--bg {
    background-color: #e2fdd9;
}
.service-color-4--bg-2 {
    background-color: #3df200;
}
.service-color-4--bg-light {
    background-color: rgba(226, 253, 217, 0.5);
}
.service-color-5--bd {
    border-color: #05f;
}
.service-color-5--bd-light {
    background-color: rgba(0, 85, 255, 0.5);
}
.service-color-5--bg {
    background-color: #d9e6ff;
}
.service-color-5--bg-2 {
    background-color: #05f;
}
.service-color-5--bg-light {
    background-color: rgba(217, 230, 255, 0.5);
}
.service-color-6--bd {
    border-color: #00fffb;
}
.service-color-6--bd-light {
    background-color: rgba(0, 255, 251, 0.5);
}
.service-color-6--bg {
    background-color: #d9fffe;
}
.service-color-6--bg-2 {
    background-color: #00fffb;
}
.service-color-6--bg-light {
    background-color: rgba(217, 255, 254, 0.5);
}
.service-color-7--bd {
    border-color: #0026ff;
}
.service-color-7--bd-light {
    background-color: rgba(0, 38, 255, 0.5);
}
.service-color-7--bg {
    background-color: #d9dfff;
}
.service-color-7--bg-2 {
    background-color: #0026ff;
}
.service-color-7--bg-light {
    background-color: rgba(217, 223, 255, 0.5);
}
.service-color-8--bd {
    border-color: #00b2ff;
}
.service-color-8--bd-light {
    background-color: rgba(0, 178, 255, 0.5);
}
.service-color-8--bg {
    background-color: #d9f4ff;
}
.service-color-8--bg-2 {
    background-color: #00b2ff;
}
.service-color-8--bg-light {
    background-color: rgba(217, 244, 255, 0.5);
}
.service-color-9--bd {
    border-color: #60f;
}
.service-color-9--bd-light {
    background-color: rgba(102, 0, 255, 0.5);
}
.service-color-9--bg {
    background-color: #e8d9ff;
}
.service-color-9--bg-2 {
    background-color: #60f;
}
.service-color-9--bg-light {
    background-color: rgba(232, 217, 255, 0.5);
}
.service-color-10--bd {
    border-color: #ff007f;
}
.service-color-10--bd-light {
    background-color: rgba(255, 0, 127, 0.5);
}
.service-color-10--bg {
    background-color: #ffd9ec;
}
.service-color-10--bg-2 {
    background-color: #ff007f;
}
.service-color-10--bg-light {
    background-color: rgba(255, 217, 236, 0.5);
}
.service-color-11--bd {
    border-color: #00e68e;
}
.service-color-11--bd-light {
    background-color: rgba(0, 230, 142, 0.5);
}
.service-color-11--bg {
    background-color: #d9fbee;
}
.service-color-11--bg-2 {
    background-color: #00e68e;
}
.service-color-11--bg-light {
    background-color: rgba(217, 251, 238, 0.5);
}
.service-color-12--bd {
    border-color: #c8ff00;
}
.service-color-12--bd-light {
    background-color: rgba(200, 255, 0, 0.5);
}
.service-color-12--bg {
    background-color: #f7ffd9;
}
.service-color-12--bg-2 {
    background-color: #c8ff00;
}
.service-color-12--bg-light {
    background-color: rgba(247, 255, 217, 0.5);
}
.service-color-13--bd {
    border-color: #ff2ee3;
}
.service-color-13--bd-light {
    background-color: rgba(255, 46, 227, 0.5);
}
.service-color-13--bg {
    background-color: #ffe0fb;
}
.service-color-13--bg-2 {
    background-color: #ff2ee3;
}
.service-color-13--bg-light {
    background-color: rgba(255, 224, 251, 0.5);
}
.service-color-14--bd {
    border-color: #f200ff;
}
.service-color-14--bd-light {
    background-color: rgba(242, 0, 255, 0.5);
}
.service-color-14--bg {
    background-color: #fdd9ff;
}
.service-color-14--bg-2 {
    background-color: #f200ff;
}
.service-color-14--bg-light {
    background-color: rgba(253, 217, 255, 0.5);
}
.service-color-15--bd {
    border-color: #0084ff;
}
.service-color-15--bd-light {
    background-color: rgba(0, 132, 255, 0.5);
}
.service-color-15--bg {
    background-color: #d9edff;
}
.service-color-15--bg-2 {
    background-color: #0084ff;
}
.service-color-15--bg-light {
    background-color: rgba(217, 237, 255, 0.5);
}
.service-color-16--bd {
    border-color: #ff2f00;
}
.service-color-16--bd-light {
    background-color: rgba(255, 47, 0, 0.5);
}
.service-color-16--bg {
    background-color: #ffe0d9;
}
.service-color-16--bg-2 {
    background-color: #ff2f00;
}
.service-color-16--bg-light {
    background-color: rgba(255, 224, 217, 0.5);
}
.service-color-17--bd {
    border-color: #bd005e;
}
.service-color-17--bd-light {
    background-color: rgba(189, 0, 94, 0.5);
}
.service-color-17--bg {
    background-color: #f5d9e7;
}
.service-color-17--bg-2 {
    background-color: #bd005e;
}
.service-color-17--bg-light {
    background-color: rgba(245, 217, 231, 0.5);
}
.service-color-18--bd {
    border-color: #bd0000;
}
.service-color-18--bd-light {
    background-color: rgba(189, 0, 0, 0.5);
}
.service-color-18--bg {
    background-color: #f5d9d9;
}
.service-color-18--bg-2 {
    background-color: #bd0000;
}
.service-color-18--bg-light {
    background-color: rgba(245, 217, 217, 0.5);
}
.service-color-19--bd {
    border-color: #00d9ff;
}
.service-color-19--bd-light {
    background-color: rgba(0, 217, 255, 0.5);
}
.service-color-19--bg {
    background-color: #d9f9ff;
}
.service-color-19--bg-2 {
    background-color: #00d9ff;
}
.service-color-19--bg-light {
    background-color: rgba(217, 249, 255, 0.5);
}
.service-color-20--bd {
    border-color: #9500ff;
}
.service-color-20--bd-light {
    background-color: rgba(149, 0, 255, 0.5);
}
.service-color-20--bg {
    background-color: #efd9ff;
}
.service-color-20--bg-2 {
    background-color: #9500ff;
}
.service-color-20--bg-light {
    background-color: rgba(239, 217, 255, 0.5);
}
.service-color-21--bd {
    border-color: #e00000;
}
.service-color-21--bd-light {
    background-color: rgba(224, 0, 0, 0.5);
}
.service-color-21--bg {
    background-color: #fad9d9;
}
.service-color-21--bg-2 {
    background-color: #e00000;
}
.service-color-21--bg-light {
    background-color: rgba(250, 217, 217, 0.5);
}
.service-color-22--bd {
    border-color: #00d118;
}
.service-color-22--bd-light {
    background-color: rgba(0, 209, 24, 0.5);
}
.service-color-22--bg {
    background-color: #d9f8dd;
}
.service-color-22--bg-2 {
    background-color: #00d118;
}
.service-color-22--bg-light {
    background-color: rgba(217, 248, 221, 0.5);
}
.service-color-23--bd {
    border-color: #6fff00;
}
.service-color-23--bd-light {
    background-color: rgba(111, 255, 0, 0.5);
}
.service-color-23--bg {
    background-color: #eaffd9;
}
.service-color-23--bg-2 {
    background-color: #6fff00;
}
.service-color-23--bg-light {
    background-color: rgba(234, 255, 217, 0.5);
}
.service-color-24--bd {
    border-color: #ff00ae;
}
.service-color-24--bd-light {
    background-color: rgba(255, 0, 174, 0.5);
}
.service-color-24--bg {
    background-color: #ffd9f3;
}
.service-color-24--bg-2 {
    background-color: #ff00ae;
}
.service-color-24--bg-light {
    background-color: rgba(255, 217, 243, 0.5);
}
.service-color-25--bd {
    border-color: #e60073;
}
.service-color-25--bd-light {
    background-color: rgba(230, 0, 115, 0.5);
}
.service-color-25--bg {
    background-color: #fbd9ea;
}
.service-color-25--bg-2 {
    background-color: #e60073;
}
.service-color-25--bg-light {
    background-color: rgba(251, 217, 234, 0.5);
}
.service-color-26--bd {
    border-color: #ffea00;
}
.service-color-26--bd-light {
    background-color: rgba(255, 234, 0, 0.5);
}
.service-color-26--bg {
    background-color: #fffcd9;
}
.service-color-26--bg-2 {
    background-color: #ffea00;
}
.service-color-26--bg-light {
    background-color: rgba(255, 252, 217, 0.5);
}
.service-color-27--bd {
    border-color: #fb0;
}
.service-color-27--bd-light {
    background-color: rgba(255, 187, 0, 0.5);
}
.service-color-27--bg {
    background-color: #fff5d9;
}
.service-color-27--bg-2 {
    background-color: #fb0;
}
.service-color-27--bg-light {
    background-color: rgba(255, 245, 217, 0.5);
}
.service-color-28--bd {
    border-color: #00f2c2;
}
.service-color-28--bd-light {
    background-color: rgba(0, 242, 194, 0.5);
}
.service-color-28--bg {
    background-color: #d9fdf6;
}
.service-color-28--bg-2 {
    background-color: #00f2c2;
}
.service-color-28--bg-light {
    background-color: rgba(217, 253, 246, 0.5);
}
.service-color-29--bd {
    border-color: #9dff00;
}
.service-color-29--bd-light {
    background-color: rgba(157, 255, 0, 0.5);
}
.service-color-29--bg {
    background-color: #f0ffd9;
}
.service-color-29--bg-2 {
    background-color: #9dff00;
}
.service-color-29--bg-light {
    background-color: rgba(240, 255, 217, 0.5);
}
.service-color-30--bd {
    border-color: #0fe000;
}
.service-color-30--bd-light {
    background-color: rgba(15, 224, 0, 0.5);
}
.service-color-30--bg {
    background-color: #dbfad9;
}
.service-color-30--bg-2 {
    background-color: #0fe000;
}
.service-color-30--bg-light {
    background-color: rgba(219, 250, 217, 0.5);
}
.service-color-31--bd {
    border-color: #ff8c00;
}
.service-color-31--bd-light {
    background-color: rgba(255, 140, 0, 0.5);
}
.service-color-31--bg {
    background-color: #ffeed9;
}
.service-color-31--bg-2 {
    background-color: #ff8c00;
}
.service-color-31--bg-light {
    background-color: rgba(255, 238, 217, 0.5);
}
