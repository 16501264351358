.alert {
    position: fixed;
    top: 2.5rem;
    left: 0;
    right: 0;
    background: #f9eff1;
    padding: 14px;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    justify-content: start;
    z-index: 9999;
    transition: opacity 150ms, transform 150ms;
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
    margin-left: 1rem;
    margin-right: 1rem;

    @media screen and (min-width: 992px) {
        margin-left: 20rem;
        margin-right: 20rem;
    }

    &::before {
        height: 100%;
        width: 4px;
        content: '';
        background-color: #ed2945;
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }

    .close_button {
        width: 34px;
        min-width: 34px;
        height: 34px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #6e759b;
        border-radius: 8px;
        font-size: 1.4rem;
        cursor: pointer;

        &:hover {
            color: #3f4254;
        }
    }

    .alert_content {
        margin-left: 1rem;

        .alert_title {
            color: #ed2945;
            display: block;
            text-transform: capitalize;
            font-size: 13.5px;
            font-weight: 700;
        }
        .alert_message {
            font-size: 13px;
            padding-top: 4px;
        }
    }

    &.alert_info {
        background: rgba(8, 148, 226, 0.038);

        &::before {
            background-color: #0894e2;
        }
        .alert_title {
            color: #0894e2;
        }
    }

    &.alert_warning {
        background: #fff5ec;

        &::before {
            background-color: #ffb36f;
        }
        .alert_title {
            color: #ffb36f;
        }
    }
}
