.tab {
	&.tab--default {
		text-transform: capitalize;
		padding: 0 16px;
		height: 48px;
		display: flex;
		cursor: pointer;
		position: relative;
		font-size: 10px;
		align-items: center;
		color: #9da0a4;

		&.tab--active {
			font-weight: 500;
			color: #263446;
			outline: none;

			// &::after {
			//     content: '';
			//     width: calc(100% - 32px);
			//     height: 2px;
			//     background-color: #27292e;
			//     position: absolute;
			//     bottom: 0;
			//     left: 16px;
			// }
		}
	}
	&.tab--bordered {
		display: flex;
		flex: 1;
		align-items: center;
		justify-content: center;
		border-radius: 6px;
		text-align: center;
		position: relative;

		&.tab--active {
			color: #2a2c32;
			font-weight: 500;
			outline: none;
			// background-color: #fff;
		}
	}
}
