.dropdown--item {
    color: #2a2c32;
    font-weight: 400;
    line-height: 22px;
    padding: 10px 24px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    position: relative;
    z-index: 1;

    &:hover {
        background-color: #f3f6f9;
    }

    &.item--selected {
        font-weight: 500;
        background-color: #f3f6f9;
    }
}
