.tabsWrapper {
    border-bottom: 1px solid #e5e7eb;
    padding-left: 24px;
    padding-right: 24px;
}
.tabs {
    display: flex;
}
.tabs_defaultTab {
    text-transform: capitalize;
    padding: 0 16px;
    height: 48px;
    display: flex;
    cursor: pointer;
    position: relative;
    font-size: 12px;
    align-items: center;
    color: #9da0a4;
}
.tabs_defaultTab.tabActive {
    font-weight: 500;
    color: #263446;
}
.tabs_defaultTab.tabActive:after {
    content: '';
    width: calc(100% - 32px);
    height: 2px;
    background-color: #27292e;
    position: absolute;
    bottom: 0;
    left: 16px;
}
.tabsContent {
    padding: 24px;
    flex: 1;
}

.tabs.tabs_bordered {
    color: #8c8b88;
    background-color: #ebebeb;
    min-height: 36px;
    border-radius: 8px;
    padding: 4px;
    display: flex;
    align-items: stretch;
    cursor: pointer;
    position: relative;
    font-size: 13px;
}
.tabs_borderedTab {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    text-align: center;
    position: relative;
}
.tabs_borderedTab.tabActive {
    outline: none;
    background-color: #fff;
    color: #2a2c32;
    font-weight: 500;
}
