.overlay--loader {
	position: fixed;
	z-index: 999;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: auto;
	background-color: rgb(255, 255, 255, 0.35);

	.loader {
		display: block;
		position: relative;
		left: 50%;
		top: 50%;
		width: 100px;
		height: 100px;
		margin: -75px 0 0 -75px;
		border-radius: 50%;
		border: 3px solid transparent;
		border-top-color: #0e131a56;
		-webkit-animation: spin 1.7s linear infinite;
		animation: spin 1.7s linear infinite;

		&::before {
			content: "";
			position: absolute;
			top: 5px;
			left: 5px;
			right: 5px;
			bottom: 5px;
			border-radius: 50%;
			border: 3px solid transparent;
			border-top-color: #0e131a56;
			-webkit-animation: spin-reverse 0.6s linear infinite;
			animation: spin-reverse 0.6s linear infinite;
		}

		&::after {
			content: "";
			position: absolute;
			top: 15px;
			left: 15px;
			right: 15px;
			bottom: 15px;
			border-radius: 50%;
			border: 3px solid transparent;
			border-top-color: #0e131a56;
			-webkit-animation: spin 1s linear infinite;
			animation: spin 1s linear infinite;
		}
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

@keyframes spin-reverse {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(-360deg);
	}
}
