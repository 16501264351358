@import '../../../fonts/fonts.css';
html,
body {
    font-size: 14px;
    padding: 0;
    margin: 0 !important;
    font-family: 'Euclid Circular B';
    height: 100%;
    width: 100%;
    line-height: normal;
}
body {
    // background-color: #fff;
    font-size: 14.5px;
    color: #222;
    width: 100%;
    height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -ms-scroll-chaining: none;
    overscroll-behavior: none;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    margin: 0 !important;
}
a {
    color: inherit;
    text-decoration: none;
}
button {
    padding: 0;
    line-height: inherit;
    cursor: pointer;
    background-color: transparent;
    background-image: none;
    -webkit-appearance: button;
    text-decoration: none;
    vertical-align: baseline;
    border: 0;
}
* {
    box-sizing: border-box;
}
blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
    margin: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: inherit;
    font-weight: inherit;
}
ol,
ul {
    list-style: none;
    margin: 0;
    padding: 0;
}
.card-item {
    display: none;
}
.card-body {
    width: 100%;
    max-width: 550px;
    margin: 0 auto;
}
.rdp-day_today {
    color: rgb(59, 189, 133) !important;
    font-weight: bold;
}
.rdp-weeknumber, .rdp-day {
    width: 100% !important;
    max-width: 100% !important;
    border-radius: 20px !important;
}
#widget.on {
    width: 100%;
    height: 100%;
    overflow: auto;
}

#widget.off {
    // width: 68%;
    // height: 58%;
    overflow: unset !important;
}

.booking-widget {
    min-height: 100%;
    .booking-wrapper {
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        background-color: transparent;
        transition: background-color 1s linear;
        -moz-transition: background-color 1s linear;
        -webkit-transition: background-color 1s linear;
        -ms-transition: background-color 1s linear;
    }
    .boking-widget--header {
        padding: 32px 16px 22px 16px;
        display: flex;
        align-items: center;
        margin: 0 auto;
        width: 100%;
        max-width: 600px;
        h3 {
            font-weight: 600;
            font-size: 1.1rem;
            text-transform: capitalize;
            text-align: left;
            flex: 1;
        }
        .booking-widget--backBtn {
            width: 28px;
            height: 28px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            &:hover {
                background-color: #f0f1f7;
            }
        }
    }
    .boking-widget--card-header {
        width: 100%;
        max-width: 600px;
        margin: 0 auto;
        padding: 13px 16px 10px 16px;
        display: flex;
        align-items: center;
        h3 {
            font-weight: 600;
            font-size: 1.1rem;
            text-transform: capitalize;
            text-align: left;
            flex: 1;
        }
        .booking-widget--backBtn {
            width: 28px;
            height: 28px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            &:hover {
                background-color: #f0f1f7;
            }
        }
    }
    .booking-widget--body {
        height: 100vh;
        overflow-x: hidden;
        overflow-y: auto;
        margin: 0 auto;
        width: 100%;
        max-width: 600px;
        padding: 10px;
    }
    .booking-widget--body-card {
        padding: 0 16px 2px 16px;
        height: 38rem;
        overflow-y: auto;
    }
    .booking-widget--body-professional {
        padding: 0 16px 24px 16px;
        height: auto;
        overflow-x: hidden;
        overflow-y: auto;
    }
    .booking-widget--body-detail {
        padding: 0 16px 24px 16px;
    }
    .booking-widget--footer {
        background: rgb(2,0,36);
        background: linear-gradient(180deg, rgba(2,0,36,0) 0%, rgba(255,255,255,0.14051558123249297) 35%, rgba(255,255,255,1) 100%);
        width: 100%;
        height: 110px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: sticky;
        align-self: flex-start;
        bottom: 0;
        pointer-events: none;
        .btn {
            pointer-events: painted;
            margin: 0 auto;
            display: block;
            width: auto;
            height: 60px;
            border-radius: 20px;
            padding: 20px;
        }
        // border-bottom-right-radius: 2rem;
        // border-bottom-left-radius: 2rem;
        display: block;
        width: 100%;
    }
    .booking-widget--footer-disabled {
        background-color: transparent;
        width: 100%;
        height: 110px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: sticky;
        align-self: flex-start;
        bottom: 0;
        pointer-events: none;
        .btn {
            background-color: #9e9e9e;
            pointer-events: painted;
        }
        // border-bottom-right-radius: 2rem;
        // border-bottom-left-radius: 2rem;
        display: none;
    }
    .personaldetail--footer {
        // background-color: white;
        // border-top: 1px solid #eee;
        // width: 100%;
        // height: 110px;
        // display: flex;
        // align-items: center;
        // justify-content: center;
        // position: sticky;
        // align-self: flex-start;
        // bottom: 35px;
        // pointer-events: none;
        // .btn {
        //     pointer-events: painted;
        // }
        // border-bottom-right-radius: 2rem;
        // border-bottom-left-radius: 2rem;
        background: rgb(2,0,36);
        background: linear-gradient(180deg, rgba(2,0,36,0) 0%, rgba(255,255,255,0.14051558123249297) 35%, rgba(255,255,255,1) 100%);
        width: 100%;
        height: 110px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: sticky;
        align-self: flex-start;
        bottom: 0;
        pointer-events: none;
        .btn {
            pointer-events: painted;
            margin: 0 auto;
            display: block;
            width: auto;
            height: 60px;
            border-radius: 20px;
            padding: 20px;
        }
        // border-bottom-right-radius: 2rem;
        // border-bottom-left-radius: 2rem;
        display: block;
        width: 100%;
    }
    .personaldetail--footer-disabled {
        // background-color: white;
        // border-top: 1px solid #eee;
        // width: 100%;
        // height: 110px;
        // display: flex;
        // align-items: center;
        // justify-content: center;
        // position: sticky;
        // align-self: flex-start;
        // bottom: 35px;
        // pointer-events: none;
        // .btn {
        //     background-color: #9e9e9e;
        //     pointer-events: painted;
        // }
        // border-bottom-right-radius: 2rem;
        // border-bottom-left-radius: 2rem;
        background-color: transparent;
        width: 100%;
        height: 110px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: sticky;
        align-self: flex-start;
        bottom: 0;
        pointer-events: none;
        .btn {
            background-color: #9e9e9e;
            pointer-events: painted;
        }
        border-bottom-right-radius: 2rem;
        border-bottom-left-radius: 2rem;
        display: none;
    }
    .carddetail--footer {
        // background-color: white;
        // border-top: 1px solid #eee;
        // width: 100%;
        // // height: 93px;
        // display: flex;
        // align-items: center;
        // justify-content: center;
        // position: sticky;
        // align-self: flex-start;
        // bottom: 28px;
        // pointer-events: none;
        // .btn {
        //     pointer-events: painted;
        //     // margin-top: 1rem;
        // }
        // border-bottom-right-radius: 2rem;
        // border-bottom-left-radius: 2rem;
        // background: rgb(2,0,36);
        // background: linear-gradient(180deg, rgba(2,0,36,0) 0%, rgba(255,255,255,0.14051558123249297) 35%, rgba(255,255,255,1) 100%);
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        align-self: flex-start;
        bottom: 0;
        pointer-events: none;
        .btn {
            pointer-events: painted;
        }
        // display: block;
    }
    .carddetail--footer-disabled {
        // background-color: white;
        // border-top: 1px solid #eee;
        // width: 100%;
        // // height: 93px;
        // display: flex;
        // align-items: center;
        // justify-content: center;
        // position: sticky;
        // align-self: flex-start;
        // bottom: 28px;
        // pointer-events: none;
        // .btn {
        //     background-color: #9e9e9e;
        //     pointer-events: painted;
        //     // margin-top: 1rem;
        // }
        // border-bottom-right-radius: 2rem;
        // border-bottom-left-radius: 2rem;

        background-color: transparent;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: sticky;
        align-self: flex-start;
        bottom: 0;
        pointer-events: none;
        .btn {
            background-color: #9e9e9e;
            pointer-events: painted;
        }
        border-bottom-right-radius: 2rem;
        border-bottom-left-radius: 2rem;
    }

    .personaldetail--footer {
        // background-color: white;
        // border-top: 1px solid #eee;
        // width: 100%;
        // height: 110px;
        // display: flex;
        // align-items: center;
        // justify-content: center;
        // position: sticky;
        // align-self: flex-start;
        // bottom: 35px;
        // pointer-events: none;
        // .btn {
        //     pointer-events: painted;
        // }
        // border-bottom-right-radius: 2rem;
        // border-bottom-left-radius: 2rem;
        background: rgb(2,0,36);
        background: linear-gradient(180deg, rgba(2,0,36,0) 0%, rgba(255,255,255,0.14051558123249297) 35%, rgba(255,255,255,1) 100%);
        width: 100%;
        height: 110px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: sticky;
        align-self: flex-start;
        bottom: 0;
        pointer-events: none;
        .btn {
            pointer-events: painted;
            margin: 0 auto;
            display: block;
            width: auto;
            height: 60px;
            border-radius: 20px;
            padding: 20px;
        }
        border-bottom-right-radius: 2rem;
        border-bottom-left-radius: 2rem;
        display: block;
        width: 100%;
    }
    .professionals-container {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 10px;

        // height: calc(100vh - 33vh);
        // overflow-x: hidden;
        // overflow-y: scroll;
    }
    .professional-card {
        padding: 12px;
        background: #fff;
        border: 1px solid #d9d9d9;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        border-radius: 16px;
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        text-align: center;
        transition: all 0.25s ease;
        opacity: 1;
        animation-name: fadeInOpacity;
        animation-iteration-count: 1;
        animation-timing-function: ease-in;
        animation-duration: 1s;
        img {
            width: 64px;
            height: 64px;
            object-fit: cover;
            margin-bottom: 14px;
            border-radius: 8px;
        }
        .professional-name {
            text-align: center;
            font-weight: 800;
            font-size: 17px;
            margin-bottom: 4px;
        }
        .professional-subText {
            color: #000;
            font-size: 0.85rem;
            display: flex;
            flex-direction: column;
            font-weight: normal;
        }
        .service-details {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-top: 0.3rem;
        }
        .next-available-date-text {
            color: #4a4a4a;
            margin-top: 1.5rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: 0.7rem;
        }
        .service-direction-title {
            font-weight: 300;
        }
        .seperator {
            margin: 16px;
            background-color: #d3d3d7;
            width: 32px;
            height: 1px;
        }
        .professional-visitText {
            font-size: 0.9rem;
            color: #cf9d65;
        }
        &.selectedProfessional {
            background-color: #000;
            color: #fff;
            border-color: #000;
            .professional-subText {
                // color: #898989;
                color: #fff;
            }
            .professional-visitText {
                color: #cf9d65;
            }
            .next-available-date-text {
                color: #fff;
            }
        }
    }

    @keyframes fadeInOpacity {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    .single-service {
        padding: 20px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #d3d3d7;
        .service-title {
            font-weight: 500;
            font-size: 16px;
            margin-bottom: 2px;
        }
        .service-description {
            font-size: 12px;
            margin-bottom: 2px;
            padding-right: 10px;
        }
        .service-duration {
            color: #999ca1;
            font-size: 0.925rem;
        }
        .service--bookWrapper {
            display: flex;
            align-items: center;
        }
        .service-price {
            font-weight: 500;
            font-size: 16px;
            margin-right: 22px;
            padding-left: 3px;
            padding-right: 3px;
        }
        .select-serviceBtn {
            box-shadow: none;
            padding: 10px 18px;
            font-size: 12px;
            border-radius: 50px;
        }
    }
    .timePicker-container {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 10px;
        padding-top: 16px;
    }
    .col-3 {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        gap: 10px;
        padding-top: 16px;
    }
    .scroll-view {
        padding: 1rem;
        overflow-y: scroll;
    }
    .timeSlot {
        margin-top: 0.5rem;
        border: 1px solid #333;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        border-radius: 20px;
        padding: 10px 20px;
        cursor: pointer;
        font-size: 0.85rem;
        &.selectedSlot {
            background-color: #000;
            color: #fff;
            border-color: #000;
        }
    }
    .userDetails-grid {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 10px;
    }
    .userDetails-group {
        margin-bottom: 8px;
    }
    @media screen and (max-width: 480px) {
        .userDetails-group {
            margin-bottom: 8px;
        }
        .service-price {
            margin-right: 5px !important;
        }
    }
    .error-text {
        color: white;
        padding: 0.3rem;
    }
    .validationField {
        background: #eb5353;
        border-bottom-right-radius: 0.325rem;
        border-bottom-left-radius: 0.325rem;
    }
    .card_error {
        background: #eb5353;
        border-radius: 0.325rem;
        margin-top: 0.3rem;
    }
    .reserveBtn--container {
        display: flex;
        margin-top: 34px;
        .btn {
            flex: 1 1 100%;
            box-shadow: none;
            width: 100%;
            max-width: unset;
        }
    }
    .agreement-txt {
        padding-top: 10px;
        color: #67717e;
        text-align: center;
        font-size: 12.5px;
        max-width: 320px;
        margin: 0 auto;
    }
    .agreement-txt-personal {
        position: absolute;
        bottom: 21px;
        padding-top: 10px;
        color: #67717e;
        text-align: center;
        font-size: 12.5px;
        max-width: 320px;
        margin: 0 auto;
        left: 0;
        right: 0;
    }
    .agreement-txt-card {
        position: absolute;
        padding-top: 10px;
        padding-left: 0rem;
        padding-right: 0rem;
        color: #67717e;
        text-align: center;
        font-size: 12.5px;
        max-width: 320px;
        margin: 0 auto;
        left: 0;
        right: 0;
        @media screen and (min-width: 0px) and (max-width: 400px) {
            padding-left: 1rem;
            padding-right: 1rem;
        }
        @media screen and (min-width: 0px) and (max-width: 360px) {
            position: relative !important;
            bottom: 6px !important;
        }
    }
    .selectDate {
        margin-top: 1rem;
        width: max-content;
    }
    .date-empty-text {
        text-align: center;
    }
    .text {
        color: black;
        padding: 1rem;
    }
    .successful--appointment-container {
        // position: absolute;
        width: 100%;
        top: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        flex-direction: column;
        .bookAgain--button {
            display: flex;
            margin-top: 1rem;
            justify-content: center;
            align-items: center;
            align-content: center;
        }
    }
    .btn-book {
        padding: 16px 26px;
        font-weight: 600;
        width: 50%;
        max-width: 300px;
        text-transform: capitalize;
        color: #27292e;
        background-color: #cf9d65;
        border-radius: 12px;
        font-size: 14px;
        cursor: pointer;
        box-shadow: rgb(54 57 60) 0px 2px 8px -3px;
    }
    .btn-book {
        box-shadow: none;
        // width: 100%;
        max-width: unset;
    }

    .successful--title, .successful--appointment-details {
        display: flex;
        justify-content: center;
        text-align: center;
        font-weight: 400;
        font-size: 14px;
    }
    .gratuity--tip {
        background-color: #fff;
        border-radius: 8px;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        padding: 12px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        margin-left: 0.5rem;

        &.gratuity_tipActive {
            border: 2px solid #000;
            background-color: #000;
            color: #fff;

            div {
                &:first-child {
                    font-weight: 500;
                }
            }
        }
    }
    .gratuity-items {
        display: flex;
        justify-content: space-around;
    }
    @media screen and (max-width: 480px) {
        display: flex;
        flex-wrap: wrap;
        /* justify-content: space-around; */
        width: fit-content;
    }

    .gratuity {
        display: flex;
        /* justify-content: center; */
        padding: 0.5rem 0.5rem;
    }
}

.business-booking-widget {
    min-height: 100%;
    .booking-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        background-color: transparent;
        transition: background-color 1s linear;
        -moz-transition: background-color 1s linear;
        -webkit-transition: background-color 1s linear;
        -ms-transition: background-color 1s linear;
    }
    .boking-widget--header {
        padding: 32px 16px 22px 16px;
        display: flex;
        align-items: center;
        margin: 0 auto;
        width: 100%;
        max-width: 600px;
        h3 {
            font-weight: 600;
            font-size: 1.1rem;
            text-transform: capitalize;
            text-align: left;
            flex: 1;
        }
        .booking-widget--backBtn {
            width: 28px;
            height: 28px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            &:hover {
                background-color: #f0f1f7;
            }
        }
    }
    .boking-widget--card-header {
        width: 100%;
        max-width: 600px;
        margin: 0 auto;
        padding: 13px 16px 10px 16px;
        display: flex;
        align-items: center;
        h3 {
            font-weight: 600;
            font-size: 1.1rem;
            text-transform: capitalize;
            text-align: left;
            flex: 1;
        }
        .booking-widget--backBtn {
            width: 28px;
            height: 28px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            &:hover {
                background-color: #f0f1f7;
            }
        }
    }
    .booking-widget--body {
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        margin: 0 auto;
        width: 100%;
        max-width: 600px;
        padding: 10px;
    }
    .booking-widget--body-card {
        padding: 0 16px 2px 16px;
        height: 38rem;
        overflow-y: auto;
    }
    .booking-widget--body-professional {
        padding: 0 16px 24px 16px;
        height: auto;
        overflow-x: hidden;
        overflow-y: auto;
    }
    .booking-widget--body-detail {
        padding: 0 16px 24px 16px;
    }
    .booking-widget--footer {
        background: rgb(2,0,36);
        background: linear-gradient(180deg, rgba(2,0,36,0) 0%, rgba(255,255,255,0.14051558123249297) 35%, rgba(255,255,255,1) 100%);
        width: 100%;
        height: 110px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: sticky;
        align-self: flex-start;
        bottom: 0;
        pointer-events: none;
        .btn {
            pointer-events: painted;
            margin: 0 auto;
            display: block;
            width: auto;
            height: 60px;
            border-radius: 20px;
            padding: 20px;
        }
        // border-bottom-right-radius: 2rem;
        // border-bottom-left-radius: 2rem;
        display: block;
        width: 100%;
    }
    .booking-widget--footer-disabled {
        background-color: transparent;
        width: 100%;
        height: 110px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: sticky;
        align-self: flex-start;
        bottom: 0;
        pointer-events: none;
        .btn {
            background-color: #9e9e9e;
            pointer-events: painted;
        }
        // border-bottom-right-radius: 2rem;
        // border-bottom-left-radius: 2rem;
        display: none;
    }
    .personaldetail--footer {
        // background-color: white;
        // border-top: 1px solid #eee;
        // width: 100%;
        // height: 110px;
        // display: flex;
        // align-items: center;
        // justify-content: center;
        // position: sticky;
        // align-self: flex-start;
        // bottom: 35px;
        // pointer-events: none;
        // .btn {
        //     pointer-events: painted;
        // }
        // border-bottom-right-radius: 2rem;
        // border-bottom-left-radius: 2rem;
        background: rgb(2,0,36);
        background: linear-gradient(180deg, rgba(2,0,36,0) 0%, rgba(255,255,255,0.14051558123249297) 35%, rgba(255,255,255,1) 100%);
        width: 100%;
        height: 110px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: sticky;
        align-self: flex-start;
        bottom: 0;
        pointer-events: none;
        .btn {
            pointer-events: painted;
            margin: 0 auto;
            display: block;
            width: auto;
            height: 60px;
            border-radius: 20px;
            padding: 20px;
        }
        // border-bottom-right-radius: 2rem;
        // border-bottom-left-radius: 2rem;
        display: block;
        width: 100%;
    }
    .personaldetail--footer-disabled {
        // background-color: white;
        // border-top: 1px solid #eee;
        // width: 100%;
        // height: 110px;
        // display: flex;
        // align-items: center;
        // justify-content: center;
        // position: sticky;
        // align-self: flex-start;
        // bottom: 35px;
        // pointer-events: none;
        // .btn {
        //     background-color: #9e9e9e;
        //     pointer-events: painted;
        // }
        // border-bottom-right-radius: 2rem;
        // border-bottom-left-radius: 2rem;
        background-color: transparent;
        width: 100%;
        height: 110px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: sticky;
        align-self: flex-start;
        bottom: 0;
        pointer-events: none;
        .btn {
            background-color: #9e9e9e;
            pointer-events: painted;
        }
        border-bottom-right-radius: 2rem;
        border-bottom-left-radius: 2rem;
        display: none;
    }
    .carddetail--footer {
        // background-color: white;
        // border-top: 1px solid #eee;
        // width: 100%;
        // // height: 93px;
        // display: flex;
        // align-items: center;
        // justify-content: center;
        // position: sticky;
        // align-self: flex-start;
        // bottom: 28px;
        // pointer-events: none;
        // .btn {
        //     pointer-events: painted;
        //     // margin-top: 1rem;
        // }
        // border-bottom-right-radius: 2rem;
        // border-bottom-left-radius: 2rem;
        // background: rgb(2,0,36);
        // background: linear-gradient(180deg, rgba(2,0,36,0) 0%, rgba(255,255,255,0.14051558123249297) 35%, rgba(255,255,255,1) 100%);
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        align-self: flex-start;
        bottom: 0;
        pointer-events: none;
        .btn {
            pointer-events: painted;
        }
        // display: block;
    }
    .carddetail--footer-disabled {
        // background-color: white;
        // border-top: 1px solid #eee;
        // width: 100%;
        // // height: 93px;
        // display: flex;
        // align-items: center;
        // justify-content: center;
        // position: sticky;
        // align-self: flex-start;
        // bottom: 28px;
        // pointer-events: none;
        // .btn {
        //     background-color: #9e9e9e;
        //     pointer-events: painted;
        //     // margin-top: 1rem;
        // }
        // border-bottom-right-radius: 2rem;
        // border-bottom-left-radius: 2rem;

        background-color: transparent;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: sticky;
        align-self: flex-start;
        bottom: 0;
        pointer-events: none;
        .btn {
            background-color: #9e9e9e;
            pointer-events: painted;
        }
        border-bottom-right-radius: 2rem;
        border-bottom-left-radius: 2rem;
    }

    .personaldetail--footer {
        // background-color: white;
        // border-top: 1px solid #eee;
        // width: 100%;
        // height: 110px;
        // display: flex;
        // align-items: center;
        // justify-content: center;
        // position: sticky;
        // align-self: flex-start;
        // bottom: 35px;
        // pointer-events: none;
        // .btn {
        //     pointer-events: painted;
        // }
        // border-bottom-right-radius: 2rem;
        // border-bottom-left-radius: 2rem;
        background: rgb(2,0,36);
        background: linear-gradient(180deg, rgba(2,0,36,0) 0%, rgba(255,255,255,0.14051558123249297) 35%, rgba(255,255,255,1) 100%);
        width: 100%;
        height: 110px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: sticky;
        align-self: flex-start;
        bottom: 0;
        pointer-events: none;
        .btn {
            pointer-events: painted;
            margin: 0 auto;
            display: block;
            width: auto;
            height: 60px;
            border-radius: 20px;
            padding: 20px;
        }
        border-bottom-right-radius: 2rem;
        border-bottom-left-radius: 2rem;
        display: block;
        width: 100%;
    }
    .professionals-container {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 10px;

        // height: calc(100vh - 33vh);
        // overflow-x: hidden;
        // overflow-y: scroll;
    }
    .professional-card {
        padding: 12px;
        background: #fff;
        border: 1px solid #d9d9d9;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        border-radius: 16px;
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        text-align: center;
        transition: all 0.25s ease;
        opacity: 1;
        animation-name: fadeInOpacity;
        animation-iteration-count: 1;
        animation-timing-function: ease-in;
        animation-duration: 1s;
        img {
            width: 64px;
            height: 64px;
            object-fit: cover;
            margin-bottom: 14px;
            border-radius: 8px;
        }
        .professional-name {
            text-align: center;
            font-weight: 800;
            font-size: 17px;
            margin-bottom: 4px;
        }
        .professional-subText {
            color: #000;
            font-size: 0.85rem;
            display: flex;
            flex-direction: column;
            font-weight: normal;
        }
        .service-details {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-top: 0.3rem;
        }
        .next-available-date-text {
            color: #4a4a4a;
            margin-top: 1.5rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: 0.7rem;
        }
        .service-direction-title {
            font-weight: 300;
        }
        .seperator {
            margin: 16px;
            background-color: #d3d3d7;
            width: 32px;
            height: 1px;
        }
        .professional-visitText {
            font-size: 0.9rem;
            color: #cf9d65;
        }
        &.selectedProfessional {
            background-color: #000;
            color: #fff;
            border-color: #000;
            .professional-subText {
                // color: #898989;
                color: #fff;
            }
            .professional-visitText {
                color: #cf9d65;
            }
            .next-available-date-text {
                color: #fff;
            }
        }
    }

    @keyframes fadeInOpacity {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    .single-service {
        padding: 20px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #d3d3d7;
        .service-title {
            font-weight: 500;
            font-size: 16px;
            margin-bottom: 2px;
        }
        .service-description {
            font-size: 12px;
            margin-bottom: 2px;
            padding-right: 10px;
        }
        .service-duration {
            color: #999ca1;
            font-size: 0.925rem;
        }
        .service--bookWrapper {
            display: flex;
            align-items: center;
        }
        .service-price {
            font-weight: 500;
            font-size: 16px;
            margin-right: 22px;
            padding-left: 3px;
            padding-right: 3px;
        }
        .select-serviceBtn {
            box-shadow: none;
            padding: 10px 18px;
            font-size: 12px;
            border-radius: 50px;
        }
    }
    .timePicker-container {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 10px;
        padding-top: 16px;
    }
    .col-3 {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        gap: 10px;
        padding-top: 16px;
    }
    .scroll-view {
        padding: 1rem;
        overflow-y: scroll;
    }
    .timeSlot {
        margin-top: 0.5rem;
        border: 1px solid #333;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        border-radius: 20px;
        padding: 10px 20px;
        cursor: pointer;
        font-size: 0.85rem;
        &.selectedSlot {
            background-color: #000;
            color: #fff;
            border-color: #000;
        }
    }
    .userDetails-grid {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 10px;
    }
    .userDetails-group {
        margin-bottom: 8px;
    }
    @media screen and (max-width: 480px) {
        .userDetails-group {
            margin-bottom: 8px;
        }
        .service-price {
            margin-right: 5px !important;
        }
    }
    .error-text {
        color: white;
        padding: 0.3rem;
    }
    .validationField {
        background: #eb5353;
        border-bottom-right-radius: 0.325rem;
        border-bottom-left-radius: 0.325rem;
    }
    .card_error {
        background: #eb5353;
        border-radius: 0.325rem;
        margin-top: 0.3rem;
    }
    .reserveBtn--container {
        display: flex;
        margin-top: 34px;
        .btn {
            flex: 1 1 100%;
            box-shadow: none;
            width: 100%;
            max-width: unset;
        }
    }
    .agreement-txt {
        padding-top: 10px;
        color: #67717e;
        text-align: center;
        font-size: 12.5px;
        max-width: 320px;
        margin: 0 auto;
    }
    .agreement-txt-personal {
        position: absolute;
        bottom: 21px;
        padding-top: 10px;
        color: #67717e;
        text-align: center;
        font-size: 12.5px;
        max-width: 320px;
        margin: 0 auto;
        left: 0;
        right: 0;
    }
    .agreement-txt-card {
        position: absolute;
        padding-top: 10px;
        padding-left: 0rem;
        padding-right: 0rem;
        color: #67717e;
        text-align: center;
        font-size: 12.5px;
        max-width: 320px;
        margin: 0 auto;
        left: 0;
        right: 0;
        @media screen and (min-width: 0px) and (max-width: 400px) {
            padding-left: 1rem;
            padding-right: 1rem;
        }
        @media screen and (min-width: 0px) and (max-width: 360px) {
            position: relative !important;
            bottom: 6px !important;
        }
    }
    .selectDate {
        margin-top: 1rem;
        width: max-content;
    }
    .date-empty-text {
        text-align: center;
    }
    .text {
        color: black;
        padding: 1rem;
    }
    .successful--appointment-container {
        // position: absolute;
        width: 100%;
        top: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        flex-direction: column;
        .bookAgain--button {
            display: flex;
            margin-top: 1rem;
            justify-content: center;
            align-items: center;
            align-content: center;
        }
    }
    .btn-book {
        padding: 16px 26px;
        font-weight: 600;
        width: 50%;
        max-width: 300px;
        text-transform: capitalize;
        color: #27292e;
        background-color: #cf9d65;
        border-radius: 12px;
        font-size: 14px;
        cursor: pointer;
        box-shadow: rgb(54 57 60) 0px 2px 8px -3px;
    }
    .btn-book {
        box-shadow: none;
        // width: 100%;
        max-width: unset;
    }

    .successful--title, .successful--appointment-details {
        display: flex;
        justify-content: center;
        text-align: center;
        font-weight: 400;
        font-size: 14px;
    }
    .gratuity--tip {
        background-color: #fff;
        border-radius: 8px;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        padding: 12px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        margin-left: 0.5rem;

        &.gratuity_tipActive {
            border: 2px solid #000;
            background-color: #000;
            color: #fff;

            div {
                &:first-child {
                    font-weight: 500;
                }
            }
        }
    }
    .gratuity-items {
        display: flex;
        justify-content: space-around;
    }
    @media screen and (max-width: 480px) {
        display: flex;
        flex-wrap: wrap;
        /* justify-content: space-around; */
        width: 100%;
    }

    .gratuity {
        display: flex;
        /* justify-content: center; */
        padding: 0.5rem 0.5rem;
    }
}

.btn {
    padding: 16px 26px;
    font-weight: 600;
    width: 100%;
    max-width: 300px;
    text-transform: capitalize;
    color: #27292e;
    background: #cf9d65;
    border-radius: 12px;
    font-size: 14px;
    cursor: pointer;
    box-shadow: rgb(54 57 60) 0px 2px 8px -3px;
    &:disabled {
        background-color: #d6d6d7;
        cursor: not-allowed;
    }
}
// Styling calendar
.rdp {
    --rdp-cell-size: 25px !important;
    --rdp-accent-color: #282828 !important;
    --rdp-background-color: transparent !important;
    --rdp-outline: 2px solid var(--rdp-accent-color) !important;
    --rdp-outline-selected: 2px solid rgba(0, 0, 0, 0.75) !important;
}
.rdp-button[disabled] {
    opacity: 0.45 !important;
}
.rdp-caption_label {
    font-weight: 500 !important;
}
.rdp-nav_button {
    svg {
        width: 14px !important;
        height: 14px !important;
    }
}
.rdp-head_cell {
    font-weight: 600 !important;
}
/* width */
::-webkit-scrollbar {
    width: 4px;
    border-radius: 5rem;
}
/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 5rem;
}
/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5rem;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}
.overFlow {
    max-height: 27rem;
    overflow: auto;
    margin-top: 10px;
    padding-top: 0rem !important;
}
#booking-overlay {
    position: fixed;
    z-index: 999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: #fff;
        transition: background-color 1s linear;
        -moz-transition: background-color 1s linear;
        -webkit-transition: background-color 1s linear;
        -ms-transition: background-color 1s linear;
}
.booking-widget-wrapper {
    z-index: 9999999;
    position: relative;
    background-color: transparent;
    height: 100vh;
    width: 100%;
    position: fixed;
    border: none;
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 0rem;
}
// #barberone-container {
//     // position: fixed;
//     // z-index: 999;
//     // left: 0px;
//     // top: 0px;
//     // width: 100%;
//     // height: 100%;
//     // overflow: auto;
//     // background-color: rgba(0, 0, 0, 0.5);
// }
// @media screen and (min-width: 100px) and (max-width: 500px) {
//     #widget.on {
//         width: 100% !important;
//         height: 33% !important;
//         overflow: auto !important;
//     }
//     .booking-widget {
//         min-height: 90% !important;
//         width: 95%;
//     }
//     .booking-widget-wrapper {
//         min-height: 90% !important;
//     }
//     .mini-calendar {
//         flex-direction: column;
//     }
//     // .booking-widget--body {
//     //     height: 44rem !important;
//     // }
//     .professional-name {
//         font-size: 11px !important;
//     }
//     .rdp-month {
//         min-width: 100% !important;
//     }
//     .rdp-table {
//         min-width: 100% !important;
//     }
// }

// Modified CSS for new Widget Jeff Garibay 7-13-2022
    #widget.on {
        width: 100% !important;
        height: 33% !important;
        overflow: auto !important;
    }
    .booking-widget {
        min-height: 100vh !important;
        width: 100%;
    }
    .booking-widget-wrapper {
        min-height: 90% !important;
    }
    .mini-calendar {
        flex-direction: column;
        padding-bottom: 10rem !important;
    }
    // .booking-widget--body {
    //     height: 44rem !important;
    // }
    .professional-name {
        font-size: 11px !important;
    }
    .rdp-month {
        min-width: 100% !important;
    }
    .rdp-table {
        min-width: 100% !important;
    }


@media screen and (min-width: 100px) and (max-width: 400px) {
    #widget.on {
        width: 100% !important;
        height: 33% !important;
        overflow: auto !important;
    }
    .booking-widget {
        min-height: 90% !important;
        width: 95%;
    }
    .booking-widget-wrapper {
        min-height: 90% !important;
    }
    .overFlow {
        max-height: 12rem;
    }
    .gratuity-items {
        flex-wrap: wrap;
        display: flex;
        justify-content: space-around;
        width: 90%;
    }
    .subTotal-div {
        height: 33rem;
        overflow: auto;
    }
    .professional-name {
        font-size: 11px !important;
    }
    .rdp-month {
        min-width: 100% !important;
    }
    .rdp-table {
        min-width: 100% !important;
    }
}

.book_appoinment {
    padding: 14px 21px;
    font-weight: 550;
    width: 13rem;
    max-width: 550px;
    text-transform: capitalize;
    color: #27292e;
    background-color: #cf9d65;
    font-size: 23px;
    border-radius: 8px;
    cursor: pointer;
    margin: 0.8rem;
    box-shadow: #36393c 0px 2px 8px -3px;
}
.closeInfoModel {
    background: url(https://www.getexclusively.com/img/close.png);
    height: 28px;
    width: 28px;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}
.grid-items {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 1rem;
    @media screen and (min-width: 0px) and (max-width: 500px) {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        gap: 1rem;
    }
}
