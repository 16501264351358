.syntaxHighlighter--container {
    position: relative;
}
.syntaxHighlighter--lang {
    position: absolute;
    top: 0;
    right: 0;
    border-bottom-left-radius: 0.75rem;
    background-color: #fbbf24;
    color: #000;
    font-weight: 600;
    letter-spacing: 0.1em;
    font-size: 0.75rem;
    line-height: 1rem;
    z-index: 2;
    text-transform: capitalize;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}
.syntaxHighlighter {
    border-radius: 8px;
    overflow: hidden;
    padding-top: 20px;
    position: relative;
    word-wrap: break-word;
}
.syntaxHighlighter--copy {
    position: absolute;
    bottom: 8px;
    right: 8px;
    background: rgba(86, 98, 115, 0.5);
    border-radius: 0.5625rem;
    color: #fff;
    cursor: pointer;
    font-size: 0.6875rem;
    font-weight: 600;
    height: 24px;
    letter-spacing: 0.5px;
    padding: 5px 9px;
    position: absolute;
    text-transform: capitalize;
    white-space: nowrap;
    width: 52px;
    outline: none;
    border: none;
}
pre {
    white-space: pre-wrap !important;
    word-break: break-all !important;
}
code {
    white-space: pre-wrap !important;
    word-break: break-all !important;
}
