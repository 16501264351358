.fc .fc-timegrid-slot {
    height: 2rem;
    color: rgba(107, 114, 128, 1);
    font-size: 0.75rem;
    cursor: pointer;
    position: relative;
}
.fc-theme-standard .fc-scrollgrid {
    border: 0;
}
.fc-theme-standard th {
    border-right: 0;
    border-left: 0;
    height: 36px;
    text-transform: capitalize;
    font-weight: 500;
    font-size: 0.9rem;
}
.fc-theme-standard th.fc-day-today {
    color: black;
    border-bottom: 3px solid red;
}
.fc .fc-daygrid-day.fc-day-today {
    background-color: transparent;
}
.fc .fc-timegrid-col {
    position: relative;
}
.fc .fc-timegrid-col.fc-day-today {
    background-color: #fff;
}
.fc .fc-highlight {
    background: transparent;
}
.fc-v-event {
    background-color: #fff;
    border-radius: 8px;
}
.fc-v-event .fc-event-main {
    color: #263446;
    cursor: pointer;
    padding: 0;
}
.fc-timegrid-event-harness-inset .fc-timegrid-event,
.fc-timegrid-event.fc-event-mirror,
.fc-timegrid-more-link {
    box-shadow: 1px 3px 9px 1px rgb(0 0 0 / 8%);
}
.fc-timegrid-event {
    // border: 1px solid #ebedf2;
    // border-top-width: 3px;
    border: none;
}
.fc-timegrid-event.fc-event-danger {
    border-top-color: #eb5353;
}
.fc-timegrid-event.fc-event-warning {
    border-top-color: #f29745;
}
.fc-timegrid-event.fc-event-success {
    border-top-color: #49c496;
}
.fc-timegrid-event.fc-event-primary {
    border-top-color: black;
}
.fc .fc-non-business {
    background-size: 18px 18px;
    background-color: #fff;
    background-image: url('/images/dayoff-pattern.svg');
}
.timegrid_hover {
    position: absolute;
    left: 0;
    top: 0;
    height: 2rem;
    width: 100%;
    padding: 0 12px;
    background: #fff;
    border-radius: 4px;
    border: 1px dashed #263446;
    color: #263446;
    font-size: 12px;
    font-weight: 500;
    pointer-events: none;
    display: flex;
    align-items: center;
    z-index: 30;
    cursor: pointer;
}
.booking_event {
    padding: 8px 8px 8px 0;
    border-radius: 8px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    position: relative;

    .booking_eventBorder {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        border-left-width: 4px;
        border-radius: 8px 0 0 8px;
    }

    .booking_eventDetails {
        padding-left: 12px;

        &.booking_eventDetails_inline {
            display: flex;

            .booking_serviceCustomer {
                order: 2;
                padding-left: 0.5rem;
            }
        }

        .booking_time {
            font-size: 12px;
            font-weight: 500;
        }
        .booking_week_time {
            font-size: 10px;
            font-weight: 500;
        }
    }
}
.fc .fc-timegrid-now-indicator-line {
    border-color: red !important;
}
.fc .fc-timegrid-now-indicator-arrow {
    border-color: red !important;
    border-top-color: transparent !important;
    border-bottom-color: transparent !important;
}

.arrowLeft {
    top: 1.5rem;
    height: 10px;
    position: absolute;
    width: 0;
    border: 10px solid transparent;
    border-top-color: black;
    cursor: pointer;
    transform: rotate(90deg);
}
.arrowRight {
    top: 1.5rem;
    height: 10px;
    position: absolute;
    width: 0;
    border: 10px solid transparent;
    border-top-color: black;
    cursor: pointer;
    right: 0;
    transform: rotate(270deg);
}
.block_bg {
    background-color: rgb(172, 172, 172);
}
