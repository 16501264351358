.dropdown {
    position: relative;
    .dropdown-content {
        display: none;
    }
    .dropdown--content {
        box-shadow: 0 16px 48px 0 rgb(62 53 71 / 18%);
        position: absolute;
        z-index: 100;
        top: calc(100% + 12px);
        left: 0;
        background: #fff;
        border-radius: 8px;
        padding: 16px 0;
        min-width: 100%;
        overflow-y: auto;
        max-height: 240px;

        &::before {
            top: -11px;
            left: 16px;
            border-width: 0 10px 12px 10px;
            border-color: transparent transparent #fff transparent;
            content: '';
            width: 0;
            height: 0;
            position: absolute;
            border-style: solid;
        }
    }

    .dropdown--wrapper {
        ul {
            padding-left: 0rem;
            li {
                &:not(:last-child) {
                    border-bottom: 1px solid #f4f4f4;
                    list-style: none;
                }
            }
        }
    }

    .dropdown--item {
        color: #2a2c32;
        font-weight: 400;
        line-height: 22px;
        padding: 10px 24px;
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        position: relative;
        z-index: 1;

        &.item--selected {
            font-weight: 500;
            background-color: #f3f6f9;

            &::after {
                position: relative;
                left: -5px;
                margin-left: 14px;
                content: '';
                -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
                height: 16px;
                width: 9px;
                border-bottom: 1px solid #555;
                border-right: 1px solid #555;
            }
        }
        .item--text {
            display: flex;
            align-items: center;
            text-align: left;
        }

        &:hover {
            background-color: #f3f6f9;
        }
    }
    .dropdown-item-list {
        color: #2a2c32;
        font-weight: 400;
        line-height: 22px;
        padding: 10px;
        display: flex;
        // justify-content: space-between;
        cursor: pointer;
        position: relative;
        z-index: 1;

        &.item--selected {
            font-weight: 500;
            background-color: #f3f6f9;

            &::after {
                position: relative;
                left: -5px;
                margin-left: 14px;
                content: '';
                -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
                height: 16px;
                width: 9px;
                border-bottom: 1px solid #555;
                border-right: 1px solid #555;
            }
        }
        .item--text {
            display: flex;
            align-items: center;
            text-align: left;
            max-width: 85px;
        }

        &:hover {
            background-color: #f3f6f9;
        }
    }
}

.select--container {
    border-radius: 0.325rem;
    background-color: #fff;
    padding: 0.75rem 1.15rem;
    transition: border-color 0.15s ease-in-out;
    position: relative;
    display: block;
    border: 1px solid rgb(229 231 235);

    .select--wrapper {
        border-width: 1px;
        display: flex;
        align-items: stretch;
    }

    .select--label-wrapper {
        position: absolute;
        max-width: calc(100% - 24px);
        top: -1px;

        label {
            color: #8c8b88;
            background: #fff;
            font-size: 10px;
            display: block;
            padding: 0 6px;
            margin-left: -6px;
            font-weight: 300;
            text-transform: capitalize;
            line-height: 2;
            opacity: 0;
            transform: translateY(50%);
            transition: all 0.15s ease-in-out;
            position: relative;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            &::before,
            &::after {
                content: ' ';
                position: absolute;
                width: 100%;
                height: 50%;
                z-index: -1;
                left: 0;
            }
            &::before {
                top: 50%;
                // background-color: #fff;
            }
            &::after {
                top: 0;
                background-color: transparent;
            }
        }
    }

    .select--input--wrapper {
        display: flex;
        flex: 1;
        align-items: center;
    }

    .select--leftSlot {
        border-style: solid;
        border-width: 1px;
        border-color: rgb(156 163 175);
        display: flex;
        justify-content: center;
        align-items: center;
        padding-right: 12px;
        margin-right: 12px;
        padding-left: 16px;
        margin-left: -16px;
    }

    &.select--opened {
        .select--icon {
            i {
                transform: rotate(180deg);
                transition: transform 0.1s ease-in-out;
            }
        }
    }

    input {
        display: block;
        background-color: transparent;
        width: 100%;
        border: none;
        min-width: 0;
        color: #263446;
        line-height: 24px;

        &::placeholder {
            font-weight: 300;
            color: #8c8b88;
        }
        &:focus {
            outline: 0;
        }
    }

    .select--icon {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 12px;
        padding-right: 16px;
        margin-right: -16px;

        > i {
            font-size: 12px;
            line-height: 1;
            cursor: pointer;
            display: flex;
            align-items: center;
            transform-origin: center;
            transition: transform 0.1s ease-in-out;
        }
    }

    &.select--active {
        .select--label-wrapper {
            label {
                transform: translateY(-50%);
                opacity: 1;
                transition: color 0.15s ease-in-out, transform 0.15s ease-in-out,
                    opacity 0.15s ease-in-out;
            }
        }
    }

    &.select--focused {
        border-color: #252f3e;
        .select--label-wrapper {
            label {
                color: #252f3e;
            }
        }
    }
}
.p15 {
    padding: 15px !important;
}
.select--menuLarge {
    .dropdown--content {
        width: 400px;
    }
}
.p-075 {
    padding: 0.75rem !important;
}
.avatar {
    width: 2rem;
    height: 2rem;
    margin-right: 0.5rem;
}
.avatar img {
    border-radius: 1rem;
    object-fit: cover;
}
